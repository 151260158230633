export const is3WSD18 = false;
export const isFLN = false;
export const isSSM508 = false;
export const isFOOKLEEUNION = false;
export const isHY202318 = false;
export const isTCM941 = false

export const COOKIESPREFIX = 'CONSTRUCTIONDASHBOARD';

export const DEFAULT_PROJECT_ID =  
    isFLN?'FLN'
    :is3WSD18?"3WSD18"
    :isSSM508?'SSM508'
    :isFOOKLEEUNION?'Fook Lee Construction Co., Ltd. Union Contractors Ltd.'
    :isHY202318?'HY/2023/18'
    :isTCM941?'tcm941'
    :'Project 1';

export const isHideDangerZone=(isTCM941||is3WSD18||isHY202318||isFOOKLEEUNION)?true:false
export const isHideConfinedSpace=(isTCM941||is3WSD18||isHY202318||isFOOKLEEUNION)?true:false
export const isHideSmartLock = (isTCM941||is3WSD18||isHY202318)?true:false
export const isHideSmartWatch = false
export const isHideSmartCamera= (isTCM941||is3WSD18)?true:false
export const isHidePermit= is3WSD18?true:false
export const isHideTracking= is3WSD18?true:false
export const isHideVR = (isTCM941||is3WSD18||isHY202318)?true:false

export const CMP_DEFAULT_FILTER= isFOOKLEEUNION? '7 Days' :"Today"

export const REFRESH_INTERVAL = 1000*30 //30 seconds 

export const PERMIT_DEFAULT_TYPE = 
    isTCM941?['Sham Shui Po','Twuen Wan','Kwai Tsing']
    :['Permit to Work', 'Permit to Move', 'Permit to Operate', 'Other Permit']

export const server_ip =''
//demo:'http://18.140.58.38:3000'
//3WSD:'http://18.143.73.17'
//FLN: 'http://13.212.187.22'
//SSM508: 'http://52.221.243.77'
//FOOKLEEUNION: 'http://18.143.149.108'
//HY/2023/18: 'http://13.250.51.217'
//tcm941: 'http://54.169.186.237'

export const GOOGLEMAPAPIKEY = 'AIzaSyBP6_3_nGzAaL1ti6OmphwQvqFI5VhYguc';